import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import './App.css';

import Home from './screens/Home';
import About from './screens/About';
import SignUp from './screens/Signup';
import Login from './screens/Login';
import CreatePost from './screens/CreatePost';
import Settings from './screens/Settings';
import UpdateProfile from './screens/UpdateProfile';

import ProjectSkeleton from './components/ProjectSkeleton';
import ProfileSkeleton from './components/ProfileSkeleton';

function App() {
  return (
    <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />}></Route>

          <Route path='/about' element={<About />}></Route>
          <Route path='/signup' element={<SignUp />}></Route>
          <Route path='/login' element={<Login />}></Route>
          <Route path='/create_post' element={<CreatePost />}></Route>
          <Route path='/settings' element={<Settings />}></Route>
          <Route path='/updateprofile' element={<UpdateProfile />}></Route>
          
          <Route path="/post/:postId" element={<ProjectSkeleton />} />
          <Route path="/profile/:profileId" element={<ProfileSkeleton />} />
        </Routes>
    </BrowserRouter>
  );
}

export default App;
