import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { firestore } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

import { Link } from 'react-router-dom';
import '../styles/compStyles/PubCurr.css';

const PubCurrSkeleton = ({ contributors, title, imageUrl, postId, onDelete }) => {
    const { profileId } = useParams();
    const [profile, setProfile] = useState(null);
    const [error, setError] = useState(null);
    const [isOwnProfile, setIsOwnProfile] = useState(false);

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const profileRef = doc(firestore, 'profiles', profileId);
                const profileDoc = await getDoc(profileRef);
                if (profileDoc.exists()) {
                    const profileData = profileDoc.data();
                    setProfile(profileData);
                } else {
                    console.error("No such document!");
                    setError("No such document!");
                }
            } catch (error) {
                console.error("Failed to fetch profile data", error);
                setError("Failed to fetch profile data");
            }
        };

        const checkIfOwnProfile = () => {
            const auth = getAuth();
            const user = auth.currentUser;
            if (user && user.uid === profileId) {
                setIsOwnProfile(true);
            } else {
                setIsOwnProfile(false);
            }
        };

        fetchProfile();
        checkIfOwnProfile();
    }, [profileId]);

    const handleDelete = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        await onDelete(postId);
    };

    return (
        <div>
            <div className='pubcurr-horizontal-line'></div>
            {isOwnProfile ?
                <div className="pubcurr-entry">
                    <Link to={`/post/${postId}`} className="pubcurr-link" onClick={() => { window.scroll(0, 0); }}>
                        <div className="pubcurr-desc">
                            <h2>{title}</h2>
                            <p>{contributors} + more</p>
                        </div>
                        {imageUrl !== 'https://firebasestorage.googleapis.com/v0/b/quuri-website.appspot.com/o/images%2Fproject-images%2F16x9_Transparent.png?alt=media&token=14c55076-f80c-4119-b56f-b510dd7ff4c4' && (
                            <div className="pubcurr-image">
                                <img src={imageUrl} alt="Project" />
                            </div>
                        )}
                    </Link>

                    <div className='pubcurr-vertical-spacer'></div>

                    <div className='pubcurr-trash'>
                        <button onClick={handleDelete} className="pubcurr-trash" title="Delete this post">
                            <p>Delete Project</p>
                        </button>
                    </div>
                </div>
            :
                <div className="pubcurr-entry-other">
                    <Link to={`/post/${postId}`} className="pubcurr-link" onClick={() => { window.scroll(0, 0); }}>
                        <div className="pubcurr-desc-other">
                            <h2>{title}</h2>
                            <p>{contributors} + more</p>
                        </div>
                        {imageUrl !== 'https://firebasestorage.googleapis.com/v0/b/quuri-website.appspot.com/o/images%2Fproject-images%2F16x9_Transparent.png?alt=media&token=14c55076-f80c-4119-b56f-b510dd7ff4c4' && (
                            <div className="pubcurr-image">
                                <img src={imageUrl} alt="Project" />
                            </div>
                        )}
                    </Link>
                </div>
            }
        </div>
    );
}

export default PubCurrSkeleton;
