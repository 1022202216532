import React, { useState } from 'react';
import { auth } from '../firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { Link } from 'react-router-dom';

import '../styles/Signin.css';
import { FaRegEnvelope, FaKey, FaCircleInfo, FaFacebookF } from "react-icons/fa6";
import { FcGoogle } from "react-icons/fc";

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const login = (e) => {
    e.preventDefault();
    setErrorMessage('');

    signInWithEmailAndPassword(auth, email, password).then((userCredential) => {
      console.log(userCredential);
      window.location.href = '/';
    }).catch((error) => {
      console.log(error);
      setErrorMessage('Invalid email or password');
    })
  }

  return (
    <div>
      <h1 className='signin-header'>Login</h1>
      <div className='login-container'>
        <div className='signin-left-container'>
          <form onSubmit={login}>
            <div className='signin-left-heading'>
              <h1>Welcome back!</h1>
              <p>Please login to continue</p>
            </div>
            <h1 className='signin-input-heading'>Email Address</h1>
            <div className='signin-input-wrapper'>
              <FaRegEnvelope id='signin-icon' />
              <input type='email' placeholder='example@gmail.com' value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
            <h1 className='signin-input-heading'>Password</h1>
            <div className='signin-input-wrapper'>
              <FaKey id='signin-key-icon' />
              <input type='password' placeholder='*********' value={password} onChange={(e) => setPassword(e.target.value)} />
            </div>
            <button className='signin-submit-button'>Login</button>
            <div className='signin-left-bottom-text'>
              <p>or</p>
              <p>Login with</p>
            </div>
            <div className="signin-methods">
              <ul className="signin-method-icons">
                <li>
                  <a className="google" href="#"><FcGoogle id='icon'/></a>
                </li>
                <li>
                  <a className="facebook" href="#"><FaFacebookF id='icon'/></a>
                </li>
              </ul>
            </div>
          </form>
        </div>
        <div className='signin-right-container' style={{ backgroundImage: "url('./images/backgrounds/SignupBackground1.png')" }}>
          <h1 className='login-h1'>Quuri</h1>
          <p>Already have an account?</p>
          <Link to='/signup'><button>Signup</button></Link>
        </div>
      </div>
      { errorMessage === '' ?
        <></>
      :  
        <div className='signin-error-message'>
          <FaCircleInfo id='error-message-icon'/><p>{errorMessage}</p>
        </div>
      }
    </div>
  )
}

export default Login