import React, { useState } from 'react';
import { FaLocationDot, FaXmark } from "react-icons/fa6";

const LocationField = ({ locationItemList, setLocationItemList }) => {
    const [locationInput, setLocationInput] = useState('');
    const [locationResults, setLocationResults] = useState([]);

    const fetchLocationData = (value) => {
        const results = ['Georgia Institute of Technology', 'Emory University'].filter((location) => {
            return value && location && location.toLowerCase().includes(value.toLowerCase());
        });
        setLocationResults(results);
    };

    const handleLocationChange = (value) => {
        setLocationInput(value);
        fetchLocationData(value);
    };

    const clearLocationInput = () => {
        setLocationResults([]);
        setLocationInput('');
    };

    const handleLocationClick = (value) => {
        if (!locationItemList.includes(value)) {
            setLocationItemList([...locationItemList, value]);
        }
        clearLocationInput();
    };

    const handleLocationSubmit = (e) => {
        if (e.keyCode === 13 && locationInput) {
            handleLocationClick(locationInput);
        }
    };

    const handleLocationDelete = (id) => {
        const updatedLocation = locationItemList.filter((_, index) => index !== id);
        setLocationItemList(updatedLocation);
    };

    return (
        <div>
            <h2>Organization</h2>
            <div className='update-profile-input-container'>
                <div className='update-profile-search-input-wrapper'>
                    {locationInput.length === 0 ? (
                        <FaLocationDot id='basic-icon'/>) : (<span id='close-icon' onClick={clearLocationInput}><FaXmark id='close-icon'/></span>)}
                    <input 
                        placeholder='ex. Georgia Institute of Technology'
                        value={locationInput} 
                        onChange={(e) => handleLocationChange(e.target.value)} 
                        onKeyDown={(e) => handleLocationSubmit(e)} 
                    />
                </div>
                <div className='update-profile-results-list'> 
                    {locationResults.map((locationResult, id) => {
                        return (
                            <div 
                                className='update-profile-search-result' 
                                key={id} 
                                onClick={() => handleLocationClick(locationResult)}
                            >
                                {locationResult}
                            </div>
                        );
                    })}
                </div>
                <div className='profile-inputted-item-container'>
                    {locationItemList.map((locationItem, id) => {
                        return (
                            <div className='profile-inputted-item' key={id}>
                                <p>{locationItem}</p>
                                <FaXmark id='profile-delete-inputted-item' onClick={() => handleLocationDelete(id)}/>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default LocationField;
