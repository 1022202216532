import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { firestore } from '../firebase';
import { doc, getDoc, updateDoc, arrayRemove, deleteDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage, ref, deleteObject, getDownloadURL } from 'firebase/storage';
import { Link } from 'react-router-dom';

import '../styles/Profile.css';
import Navbar from '../components/navigation/Navbar';
import Footer from '../components/navigation/Footer';
import PubCurrSkeleton from './PubCurrSkeleton';

const roleToImageMap = {
    "Adjunct Professor": "AdjProf.png",
    "Associate Professor": "AsscProf.png",
    "Assistant Professor": "AsstProf.png",
    "Department Chair": "DeptChair.png",
    "Masters Researcher": "Masters.png",
    "Phd Candidate": "PhDCand.png",
    "Phd Student": "PhDStud.png",
    "Principle Investigator": "PI.png",
    "Post Doctorate Researcher": "PostDoc.png",
    "Professor": "Professor.png",
    "Research Technician": "ResTech.png",
    "Undergraduate Researcher": "UndgradRes.png",
    "Quuri Admin": "QuuriAdmin.png",
};

const ProfileSkeleton = () => {
    const { profileId } = useParams();
    const [profile, setProfile] = useState(null);
    const [badgeUrls, setBadgeUrls] = useState([]);
    const [error, setError] = useState(null);
    const [isOwnProfile, setIsOwnProfile] = useState(false);
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const profileRef = doc(firestore, 'profiles', profileId);
                const profileDoc = await getDoc(profileRef);
                if (profileDoc.exists()) {
                    const profileData = profileDoc.data();
                    setProfile(profileData);
                    fetchBadgeUrls(profileData.role);
                    fetchProjects(profileData.currProjects);
                } else {
                    console.error("No such document!");
                    setError("No such document!");
                }
            } catch (error) {
                console.error("Failed to fetch profile data", error);
                setError("Failed to fetch profile data");
            }
        };

        const fetchBadgeUrls = async (roles) => {
            if (!roles || roles.length === 0) return;

            const storage = getStorage();
            const urls = await Promise.all(
                roles.map(async (role) => {
                    const imageName = roleToImageMap[role];
                    const imageRef = ref(storage, `images/profile-badges/${imageName}`);
                    return await getDownloadURL(imageRef);
                })
            );
            setBadgeUrls(urls);
        };

        const fetchProjects = async (currProjects) => {
            if (!currProjects || currProjects.length === 0) return;

            const projectDocs = await Promise.all(
                currProjects.map(async (projectId) => {
                    const projectRef = doc(firestore, 'posts', projectId); // changed to 'posts'
                    const projectDoc = await getDoc(projectRef);
                    return { id: projectDoc.id, ...projectDoc.data() };
                })
            );

            setProjects(projectDocs);
        };

        const checkIfOwnProfile = () => {
            const auth = getAuth();
            const user = auth.currentUser;
            if (user && user.uid === profileId) {
                setIsOwnProfile(true);
            } else {
                setIsOwnProfile(false);
            }
        };

        (async () => {
            await fetchProfile();
            await checkIfOwnProfile();
        })();
    }, [profileId]);

    const handleDeleteProject = async (projectId) => {
        try {
            // Fetch the project document to get the imageURL
            const projectRef = doc(firestore, 'posts', projectId);
            const projectDoc = await getDoc(projectRef);

            if (projectDoc.exists()) {
                const projectData = projectDoc.data();
                const imageUrl = projectData.imageUrl;

                // Delete the image from Firebase Storage
                if (imageUrl && imageUrl !== 'https://firebasestorage.googleapis.com/v0/b/quuri-website.appspot.com/o/images%2Fproject-images%2F16x9_Transparent.png?alt=media&token=14c55076-f80c-4119-b56f-b510dd7ff4c4') {
                    const storage = getStorage();
                    const imageRef = ref(storage, imageUrl);

                    // Delete only the specific file, not the whole directory
                    await deleteObject(imageRef);
                }

                // Delete the project document from 'posts' collection
                await deleteDoc(projectRef);

                // Remove the projectId from the user's currProjects array
                await updateDoc(doc(firestore, 'profiles', profileId), {
                    currProjects: arrayRemove(projectId)
                });

                // Update the local state
                setProjects(projects.filter(project => project.id !== projectId));
            } else {
                console.error("Project document does not exist!");
            }
        } catch (error) {
            console.error("Failed to delete project", error);
        }
    };

    if (error) {
        return <div>{error}</div>;
    }

    if (!profile) {
        return <div>Loading...</div>;
    }
    
    return (
        <div className='main-container'>
            <Navbar />

            <div className="columns-group">
                <div className="pic-column">
                    <img src={profile.profileImageURL} alt="ProfilePic"/>
                </div>

                <div className="info-column">
                    {/* Name */}
                    <h1>{profile.firstName} {profile.lastName}</h1>
                    {/* Location */}
                    <h2>{profile.location.map((location, index) => (
                            <React.Fragment key={index}>
                                {location}{index < profile.location.length - 1 && ', '}<br></br>
                            </React.Fragment>
                        ))}
                    </h2>
                    {/* Lab Group */}
                    <h2>{profile.labName.map((labName, index) => (
                            <React.Fragment key={index}>
                                {labName}{index < profile.labName.length - 1 && ', '}<br></br>
                            </React.Fragment>
                        ))}
                    </h2>

                    {/* Update Profile Conditional */}
                    <div className="info-buttons">
                        {isOwnProfile && (
                            <Link to='/updateprofile' onClick={() => { window.scroll(0, 0); }}>
                                <p>Update Profile</p>
                            </Link>
                        )}
                    </div>
                </div>

                {/* Badges */}
                <div className="badge-column">
                    {badgeUrls.map((url, index) => (
                        <img key={index} src={url} alt="Role Badge" />
                    ))}
                </div>
            </div>

            <div className="columns-group">
                <div className="profile-left-column">
                    <div className="bio-group">
                        {/* Biography Group */}
                        <p>{profile.description}</p>

                        <div className="profile-horizontal-spacer"></div>

                        {/* Education Group */}
                        <h1>Education</h1>
                        <p>{profile.education.map((education, index) => (
                            <React.Fragment key={index}>
                                {education}{index < profile.education.length - 1 && ', '}<br></br>
                            </React.Fragment>
                            ))}
                        </p>
                    </div>

                    <div className='profile-horizontal-line'></div>

                    {/* Public & Current Projects Group */}
                    <div className="pubcurr-group">
                        <h1>Public & Current Projects</h1>
                        {projects.map((project, index) => (
                            <PubCurrSkeleton
                                key={index}
                                postId={project.id}
                                title={project.title}
                                contributors={project.contributors[0]}
                                location={project.location[0]}
                                imageUrl={project.imageUrl || './images/posts/default.jpg'}
                                timestamp={project.timestamp}
                                onDelete={handleDeleteProject}
                            />
                        ))}
                    </div>
                </div>

                <div className="profile-right-column">
                    {/* Helpful Links */}
                    <div className="helpful-links-group">
                        <h1>Helpful Links</h1>
                        <p>
                            {profile.links?.linkedIn && (
                                <a href={profile.links.linkedIn} target="_blank" rel="noopener noreferrer">LinkedIn</a>
                            )}
                        </p>
                        <p>
                            {profile.links?.googleScholar && (
                                <a href={profile.links.googleScholar} target="_blank" rel="noopener noreferrer">Google Scholar</a>
                            )}
                        </p>
                    </div> 

                    <div className='profile-horizontal-line'></div> 

                    {/* Interest Tags */}
                    <div className="profile-tag-list-group">
                        <h1>Interests</h1>

                        <div className="project-tags">
                            {profile.interests.map((interest, index) => (
                                <p className="profile-interests" key={index}>{interest}</p>
                            ))}
                        </div>
                    </div>       
                </div>

            </div>

            <div className='profile-horizontal-spacer'></div>
            
            <Footer />
        </div>
    )
}

export default ProfileSkeleton;
