import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth, firestore } from '../../firebase';
import { doc, getDoc } from "firebase/firestore";
import '../../styles/compStyles/Navbar.css';
import { FaHouse, FaPlus, FaMagnifyingGlass, FaXmark, FaBars, FaRegFileLines, FaGear, FaArrowRightToBracket, FaCircleInfo } from "react-icons/fa6";
//import { AiOutlineMessage } from "react-icons/ai";
//import { BsBell } from "react-icons/bs";

const Navbar = () => {
  const [authUser, setAuthUser] = useState(null);
  const [open, setOpen] = useState(false);
  const [username, setUsername] = useState('');
  const [profileImageURL, setProfileImageURL] = useState('');
  const [profileId, setProfileId] = useState('');

  useEffect(() => {
    const listen = onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthUser(user);
        setProfileId(user.uid); // Set the profile ID

        const docRef = doc(firestore, 'profiles', user.uid);
        getDoc(docRef).then((doc) => {
          setUsername(doc.data().username);
          setProfileImageURL(doc.data().profileImageURL);
        });
      } else {
        setAuthUser(null);
        setProfileId(''); // Reset the profile ID
      }
    });

    return () => {
      listen();
    };
  }, []);

  const userSignOut = () => {
    signOut(auth).then(() => {
      console.log("Sign out successful");
      window.location.href = '/';
    }).catch((error) => {
      console.log(error);
    });
  };

  const [errorMessage, setErrorMessage] = useState('');
  const displayError = () => {
    setErrorMessage('Sign in to use this feature');
    setTimeout(() => {
      setErrorMessage('');
    }, 4000);
  };

  const [input, setInput] = useState('');
  const [results, setResults] = useState([]);

  const fetchData = (value) => {
    fetch("https://jsonplaceholder.typicode.com/posts")
      .then((response) => response.json())
      .then((json) => {
        const results = json.filter((post) => {
          return value && post.title && post.title.toLowerCase().includes(value);
        });
        setResults(results);
      });
  };

  const handleChange = (value) => {
    setInput(value);
    fetchData(value);
  };

  const clearInput = () => {
    setResults([]);
    setInput('');
  };

  return (
    <div className='topnav'>
      {authUser ? (
        <Link to='/create_post' onClick={() => { window.scroll(0, 0); }}>
          <div className='create-post-button'>
            <FaPlus id='add-post-icon' /><p>Create Post</p>
          </div>
        </Link>
      ) : (
        <></>
      )}
      
      <Link to='/' onClick={() => { window.scroll(0, 0); }}>
        <img className='logo' src='https://firebasestorage.googleapis.com/v0/b/quuri-website.appspot.com/o/images%2Fstatic%2FQuuriLogo.png?alt=media&token=48892506-64a2-4278-ab56-974c2236f3e3' alt='Quuri Logo' />
      </Link>

      <div className='searchbar'>
        <div className='search-input-wrapper' setResults={setResults}>
          {input.length === 0 ? (
            <FaMagnifyingGlass id='search-icon' />
          ) : (
            <FaXmark id='close-icon' onClick={clearInput} />
          )}
          <input placeholder='Search...' value={input} onChange={(e) => handleChange(e.target.value)} />
        </div>
        <div className='results-list'>
          {results.map((result, id) => {
            return <div className='search-result' href={result.title} key={id} onClick={() => handleChange(result.title)}>{result.title}</div>;
          })}
        </div>
      </div>
      
      <div className='nav-links'>
        <Link className='page' to='/'><FaHouse /></Link>
        {authUser ? (
          <></>
          //<Link className='page' to='/'><AiOutlineMessage /></Link>
        ) : (
          <Link className='page' to='/about'><FaCircleInfo /></Link>
        )}
        {/* {authUser && (
          <Link className='page' to='/'><BsBell /></Link>
        )} */}
        
        {authUser ? (
          <div>
            <FaBars id='nav-dropdown-button' onClick={() => setOpen(!open)} />
            <div className={`nav-dropdown-menu ${open ? 'active' : 'inactive'}`}>
              <ul>
                <li>
                  <Link to={`/profile/${profileId}`}>
                    <div className='nav-dropdown-profile'>
                      <img className='nav-profile-img' src={profileImageURL} alt="Profile" />
                      <div className="name-username">
                        <p className="name">Profile</p>
                        <p className="username">@{username}</p>
                      </div>
                    </div>
                  </Link>
                </li>
                <hr className='nav-profile-hr' />
                {/* <li>
                  <Link to='/projects'>
                    <div className='nav-dropdown-item'>
                      <FaRegFileLines id='nav-dropdown-icon' /><p>Posts</p>
                    </div>
                  </Link>
                </li> */}
                <li>
                  <Link to='/about'>
                    <div className='nav-dropdown-item'>
                      <FaCircleInfo id='nav-dropdown-icon' /><p>About Us</p>
                    </div>
                  </Link>
                </li>
                <hr />
                <li>
                  <Link to='/settings'>
                    <div className='nav-dropdown-item'>
                      <FaGear id='nav-dropdown-icon' /><p>Settings</p>
                    </div>
                  </Link>
                </li>
                <li>
                  <button onClick={userSignOut}>
                    <div className='nav-dropdown-item'>
                      <FaArrowRightToBracket id='nav-dropdown-icon' /><p>Log Out</p>
                    </div>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        ) : (
          <div className='nav-signin-buttons'>
            <Link className='login-button' to='/login'>Login</Link>
            <Link className='signup-button' to='/signup'>Signup</Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;