import React, { useState, useRef, useEffect } from 'react';
import { firestore } from '../firebase';
import { doc, getDoc, updateDoc } from '@firebase/firestore';
import { ref, uploadBytes, getDownloadURL, getStorage } from '@firebase/storage';
import { getAuth } from 'firebase/auth';

import '../styles/UpdateProfile.css';
import { FaLinkedin, FaGoogleScholar } from "react-icons/fa6";
import Navbar from '../components/navigation/Navbar';
import Footer from '../components/navigation/Footer';

import EducationField from '../components/handlers/EducationField';
import InterestsField from '../components/handlers/InterestsField';
import LocationField from '../components/handlers/LocationField';
import LabNameField from '../components/handlers/LabNameField';
import RoleField from '../components/handlers/RoleField';

const roles = [
    "Adjunct Professor",
    "Associate Professor",
    "Assistant Professor",
    "Department Chair",
    "Masters Researcher",
    "PhD Candidate",
    "PhD Student",
    "Principle Investigator",
    "Post Doctorate Researcher",
    "Professor",
    "Research Technician",
    "Undergraduate Researcher"
];

const UpdateProfile = () => {
    const auth = getAuth();
    const storage = getStorage();
    const user = auth.currentUser;

    const [isUploading, setIsUploading] = useState(false);

    const descRef = useRef();
    const googleScholarRef = useRef();
    const linkedInRef = useRef();

    const [userId, setUserId] = useState(null);

    const [imageURL, setImageURL] = useState('');
    const [username, setUsername] = useState('');
    const [interestsItemList, setInterestsItemList] = useState([]);
    const [educationItemList, setEducationItemList] = useState([]);
    const [locationItemList, setLocationItemList] = useState([]);
    const [labNameItemList, setLabNameItemList] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [links, setLinks] = useState({ googleScholar: '', linkedIn: '' });

    useEffect(() => {
        if (user) {
            setUserId(user.uid);
            fetchProfileData(user.uid);
        } else {
            console.log('No user is signed in');
        }
    }, [user]);

    const fetchProfileData = async (uid) => {
        const profileDoc = doc(firestore, 'profiles', uid);
        try {
            const profileSnapshot = await getDoc(profileDoc);
            if (profileSnapshot.exists()) {
                const profileData = profileSnapshot.data();
                descRef.current.value = profileData.description || '';
                googleScholarRef.current.value = profileData.links?.googleScholar || '';
                linkedInRef.current.value = profileData.links?.linkedIn || '';
                setUsername(profileData.username);
                setImageURL(profileData.profileImageURL)
                setLocationItemList(profileData.location || []);
                setInterestsItemList(profileData.interests || []);
                setEducationItemList(profileData.education || []);
                setLabNameItemList(profileData.labName || []);
                setSelectedRoles(profileData.role || []);
                setLinks(profileData.links || {});
            }
        } catch (e) {
            console.error('Error fetching profile data:', e);
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();

        if (!userId) {
            console.log('No user is signed in');
            return;
        }

        setIsUploading(true);

        const profileDoc = doc(firestore, 'profiles', userId);

        const description = descRef.current.value;
        const googleScholar = googleScholarRef.current.value;
        const linkedIn = linkedInRef.current.value;

        try {
            await updateDoc(profileDoc, {
                description,
                username: username,
                education: educationItemList,
                location: locationItemList,
                interests: interestsItemList,
                labName: labNameItemList,
                role: selectedRoles,
                links: { googleScholar, linkedIn }
            });
            console.log('Profile updated successfully');
        } catch (e) {
            console.error('Error updating profile:', e);
        }
        setIsUploading(false);

        setTimeout(function() {window.location.href = ('/profile/' + userId);}, 1000);
    };

    const handleImageChange = async (e) => {
        if (e.target.files[0]) {
            try {
                const storageRef = ref(storage, `images/profile-images/${e.target.files[0].name}`);
                const snapshot = await uploadBytes(storageRef, e.target.files[0]);
                setImageURL(await getDownloadURL(snapshot.ref));
                console.log('Image uploaded:', imageURL);
            } catch (error) {
                console.error('Error uploading image:', error);
                setIsUploading(false);
                return;
            }
        }
    };

    const deleteImage = () => {
        setImageURL("https://firebasestorage.googleapis.com/v0/b/quuri-website.appspot.com/o/images%2Fstatic%2Fprofile.png?alt=media&token=5c64df22-2c56-4124-9238-519c354b9367");
    }

    return (
        <div>
            <Navbar />
            <div className='update-profile-main-container' style={{ backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/quuri-website.appspot.com/o/images%2Fstatic%2FUpdateProfileBackground.png?alt=media&token=8fbc97cf-9bc5-4059-97e9-c091179d7b75)" }}>
                <h1>Update Profile</h1>

                <div className='update-profile-inner-container'>
                    <div className='update-profile-column-container'>
                        <div className='update-profile-left-column'>
                            <div className='update-profile-image-settings'>
                                <img src={imageURL} />
                                <label for='image-upload' className='image-upload-button'>Upload New</label>
                                <input id='image-upload' type='file' accept='image/*' onChange={(e) => handleImageChange(e)} />
                                <button className='image-delete-button' type='button' onClick={() => deleteImage()}>Delete</button>
                            </div>
                            
                            {/* Username Input */}
                            <h2>Username</h2>
                            <div>
                                <input className='update-profile-search-input-wrapper' placeholder={username} onChange={(e) => setUsername(e.target.value)}/>
                            </div>

                            <div className='update-profile-vertical-spacer'></div>

                            {/* Location Input */}
                            <LocationField 
                                locationItemList={locationItemList} 
                                setLocationItemList={setLocationItemList} 
                            />

                            <div className='update-profile-vertical-spacer'></div>

                            {/* Lab Name Input */}
                            <LabNameField 
                                labNameItemList={labNameItemList} 
                                setLabNameItemList={setLabNameItemList} 
                            />

                            <div className='update-profile-vertical-spacer'></div>

                            {/* Interests Input */}
                            <InterestsField 
                                interestsItemList={interestsItemList} 
                                setInterestsItemList={setInterestsItemList} 
                            />

                            <div className='update-profile-vertical-spacer'></div>

                            {/* Education Input */}
                            <EducationField 
                                educationItemList={educationItemList} 
                                setEducationItemList={setEducationItemList} 
                            />

                            <div className='update-profile-vertical-spacer'></div>

                            {/* Links Input */}
                            <h2>Links</h2>
                            <div className='update-profile-search-input-wrapper'>
                                <FaLinkedin />
                                <input placeholder='LinkedIn' name='LinkedIn' ref={linkedInRef} />
                            </div>
                            <div className='update-profile-small-vertical-spacer'></div>
                            <div className='update-profile-search-input-wrapper'>
                                <FaGoogleScholar />
                                <input placeholder='Google Scholar' name='googleScholar' ref={googleScholarRef} />
                            </div>
                        </div>

                        <div className='update-profile-horizontal-spacer'></div>

                        <div className='update-profile-right-column'>
                            {/* Roles Input */}
                            <RoleField
                                roles={roles}
                                selectedRoles={selectedRoles}
                                setSelectedRoles={setSelectedRoles}
                            />
                        </div>
                    </div>

                    <div className='update-profile-vertical-spacer'></div>

                    {/* Description Input */}
                    <h2>Profile Description</h2>
                    <div className='description-input-wrapper'>
                        <textarea 
                            placeholder='Description' 
                            name='description' 
                            ref={descRef} 
                            rows="5"
                            style={{ width: '100%', resize: 'vertical', padding: '10px', borderRadius: '5px' }}
                        />
                    </div>

                    <button className='submit-profile-button' type='submit' onClick={handleUpdate} disabled={isUploading}>
                        {isUploading ? 'Uploading...' : 'Upload Profile'}
                    </button>

                    <div className='update-profile-vertical-spacer'></div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default UpdateProfile;
