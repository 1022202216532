import React, { useState } from 'react';
import { FaHeart, FaXmark } from "react-icons/fa6";

const InterestsField = ({ interestsItemList, setInterestsItemList }) => {
    const [interestsInput, setInterestsInput] = useState('');
    const [interestsResults, setInterestsResults] = useState([]);

    const fetchInterestsData = (value) => {
        const results = ['Biochemistry', 'Deep Learning'].filter((interests) => {
            return value && interests && interests.toLowerCase().includes(value.toLowerCase());
        });
        setInterestsResults(results);
    };

    const handleInterestsChange = (value) => {
        setInterestsInput(value);
        fetchInterestsData(value);
    };

    const clearInterestsInput = () => {
        setInterestsResults([]);
        setInterestsInput('');
    };

    const handleInterestsClick = (value) => {
        if (!interestsItemList.includes(value)) {
            setInterestsItemList([...interestsItemList, value]);
        }
        clearInterestsInput();
    };

    const handleInterestsSubmit = (e) => {
        if (e.keyCode === 13 && interestsInput) {
            handleInterestsClick(interestsInput);
        }
    };

    const handleInterestsDelete = (id) => {
        const updatedInterests = interestsItemList.filter((_, index) => index !== id);
        setInterestsItemList(updatedInterests);
    };

    return (
        <div>
            <h2>Interests</h2>
            <div className='update-profile-input-container'>
                <div className='update-profile-search-input-wrapper'>
                    {interestsInput.length === 0 ? (
                        <FaHeart id='basic-icon'/>) : (<span id='close-icon' onClick={clearInterestsInput}><FaXmark id='close-icon'/></span>)}
                    <input 
                        placeholder='ex. Bioinformatics'
                        value={interestsInput} 
                        onChange={(e) => handleInterestsChange(e.target.value)} 
                        onKeyDown={(e) => handleInterestsSubmit(e)} 
                    />
                </div>
                <div className='update-profile-results-list'> 
                    {interestsResults.map((interestsResult, id) => {
                        return (
                            <div 
                                className='update-profile-search-result' 
                                key={id} 
                                onClick={() => handleInterestsClick(interestsResult)}
                            >
                                {interestsResult}
                            </div>
                        );
                    })}
                </div>
                <div className='profile-inputted-item-container'>                    
                    {interestsItemList.map((interestsItem, id) => {
                        return (
                            <div className='profile-inputted-item' key={id}>
                                <p>{interestsItem}</p>
                                <FaXmark id='profile-delete-inputted-item' onClick={() => handleInterestsDelete(id)}/>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default InterestsField;