import React from 'react'
import { Link } from 'react-router-dom';
import Navbar from '../components/navigation/Navbar';
import Footer from '../components/navigation/Footer';
import '../styles/About.css';

const About = () => {
  return (
    <div class='main-container'>
      <Navbar />

      {/* Title Block
      <div class='tag-row'>
        <Link to='/' onClick={() => { window.scroll(0, 0); }}>
          <h1>home/ </h1> </Link>

        <Link to='/about' onClick={() => { window.scroll(0, 0); }}>
          <h1> about/</h1> </Link>
      </div> */}

      {/* Mission Statement Block */}
      <div class="about-mission">
        <div class="about-mission-left">
          <h1>Create Impact, Together.</h1>
          <p>
            Quuri is revolutionizing academic collaboration by creating a
            dynamic platform where researchers can share their ongoing projects
            and interact with a global community of scholars. The greatest
            breakthroughs happen through collective effort and open dialogue.
            Our mission is to eliminate the silos in research, accelerate
            discoveries, and cultivate a supportive network that drives
            innovation and excellence in every field.
          </p>
          <img src="./images/about-screen/Mission1.jpg" alt="Mission1Image" />
        </div>

        <div className='vertical-spacer'></div>

        <div class="about-mission-right">
          <img src="./images/about-screen/Mission2.jpg" alt="Mission2Image" />
          <h1>Stay connected with research happening, right now.</h1>
          <p>
            Quuri allows you to follow ongoing studies, engage with emerging
            data, and interact with leading scholars as discoveries unfold. With
            Quuri, staying informed has never been easier or more immediate.
            Dive into the world of live research updates and be part of the
            knowledge revolution today!
          </p>
        </div>
      </div>

      {/* Qualities Block */}
      <div class="about-qual">
        <div class="about-qual-left">
          <img src="./images/about-screen/NetworkIcon.png" alt="Network" />
          <h1>Network</h1>
          <p>
            Get connected with fellow researchers, academic institutions, and
            funding agencies.
          </p>

          <div className='horizontal-spacer'></div>

          <img src="./images/about-screen/CommIcon.png" alt="Community" />
          <h1>Community</h1>
          <p>Access to a trustworthy community of academics.</p>
        </div>

        <div className='vertical-spacer'></div>

        <div class="about-qual-right">
          <img src="./images/about-screen/TimeIcon.png" alt="Save Time" />
          <h1>Save Time</h1>
          <p>
            Accelerate your own research by working with others in similar
            fields.
          </p>

          <div className='horizontal-spacer'></div>

          <img src="./images/about-screen/CredIcon.png" alt="Credibility" />
          <h1>Credibility</h1>
          <p>
            Merit based system to facilitate credible information and resources.
          </p>
        </div>
      </div>


      {/* Co-founder Block */}
      <div class="about-cofound">
        <div class="about-cofound-title">
          <h1>Who We Are</h1>
        </div>
        <div class="about-cofound-horizontal">
          <div class="about-cofound-column">
            <img
              src="./images/about-screen/LaurenSProfile.jpeg"
              alt="LaurenProfile"
            />
            <h2>Lauren Sabo</h2>
            <p>Co-founder, Chief of Product Design & Research</p>
          </div>
          <div class="about-cofound-column">
            <img
              src="./images/about-screen/JasonWProfile.jpg"
              alt="JasonProfile"
            />
            <h2>Jason Wrzesien</h2>
            <p>Co-founder, Chief of Product Development</p>
          </div>
        </div>
      </div>

      <div className='horizontal-spacer'></div>

      <Footer />
    </div>
  )
}

export default About