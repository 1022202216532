import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import calculateTimeDifference from "./calculations/timeDifference";
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import '../styles/Post.css';

const PostSkeleton = ({ contributors, location, timestamp, title, imageUrl, postId, author_uid, tags }) => {
    const [profileImageURL, setProfileImageURL] = useState('images/static/profile.png');
    const [profileID, setProfileID] = useState(null);

    useEffect(() => {
        const fetchProfileImage = async () => {
            try {
                const db = getFirestore();
                const profileRef = doc(db, 'profiles', author_uid);
                const profileSnap = await getDoc(profileRef);

                if (profileSnap.exists()) {
                    setProfileImageURL(profileSnap.data().profileImageURL || 'images/static/profile.png');
                    setProfileID(profileSnap.data().userID);
                }
            } catch (error) {
                console.error("Error fetching profile picture:", error);
            }
        };

        fetchProfileImage();
    }, [author_uid]);

    useEffect(() => {
        console.log("Tags received:", tags);
    }, [tags]);

    return (
        <div className="post">
            <div className='post-profile-column'>
                <Link to={`/profile/${author_uid}`} onClick={() => { window.scroll(0, 0); }}>
                    <img className='profile-img' src={profileImageURL} alt='Profile Image' />
                </Link>
            </div>

            <div className='post-info-column'>
                <Link to={`/post/${postId}`} onClick={() => { window.scroll(0, 0); }}>
                    <div className='authors'>
                        <p>{contributors} + more</p>
                        <p className='lab'>at the {location}</p>
                    </div>

                    <p className='time'>{calculateTimeDifference(timestamp)}</p>

                    <div className='post-horizontal-spacer'></div>

                    <div className='thumbnail'>
                        <h1>{title}</h1>
                    </div>
                    <div className='thumbnail'>
                        <div className="post-project-tag-list">
                            <div className="post-project-tags">
                                {tags && tags.map((tag, index) => (
                                    <p className="post-project-tag" key={index}>{tag}</p>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className='post-horizontal-spacer'></div>
                </Link>
            </div>

            <div className='post-vertical-spacer'></div>

            <div className='post-image-column'>
                <img className='profile-img' src={imageUrl} alt='Post Image'/>
            </div>
        </div>
    );
};

export default PostSkeleton;