import React, { useState, useEffect } from 'react';
import { firestore } from '../firebase.js';
import { collection, getDocs } from '@firebase/firestore';
import PostSkeleton from '../components/PostSkeleton.js';

import '../styles/Home.css';
import Navbar from '../components/navigation/Navbar';
import NondescriptColumn from '../components/NondescriptColumn.js';

const Home = () => {
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const fetchPosts = async () => {
            const postsCollection = collection(firestore, 'posts');
            const postSnapshot = await getDocs(postsCollection);
            const postList = postSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setPosts(postList);
        };

        fetchPosts();
    }, []);

    return (
        <div className='main-container'>
            <Navbar />
            <div className='home-main-container'>
                <div className='home-vertical-spacer'></div>

                {/* Feed Column */}
                <div className='feed-column'>
                    <div class='posts'>
                        {posts.map((post) => (
                            <PostSkeleton
                                postId={post.id}
                                title={post.title}
                                contributors={post.contributors[0]}
                                location={post.location[0]}
                                imageUrl={post.imageUrl || './images/posts/default.jpg'}
                                timestamp={post.timestamp}
                                author_uid={post.author_uid}
                                tags={post.tags}
                            />
                        ))}
                    </div> 
                </div>

                <div className='home-vertical-spacer'></div>

                {/* Nondescript Column */}
                <NondescriptColumn />
            </div>
        </div>
    );
};

export default Home;