import React, { useState, useRef } from 'react';
import { auth, firestore } from '../firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc } from '@firebase/firestore';
import { Link } from 'react-router-dom';

import '../styles/Signin.css';
import adjectives from '../data/adjectives.json';
import animals from '../data/animals.json';
import { FaRegEnvelope, FaKey, FaCircleInfo, FaFacebookF } from "react-icons/fa6";
import { FcGoogle } from "react-icons/fc";

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const firstNameRef = useRef();
  const lastNameRef = useRef();

  const signUp = (e) => {
    e.preventDefault();
    setErrorMessage('');

    if (!(password === confirmPassword)) {
      return setErrorMessage('Passwords do not match');
    }
    
    createUserWithEmailAndPassword(auth, email, password).then((userCredential) => {
      console.log(userCredential);

      setDoc(doc(firestore, 'profiles', userCredential.user.uid), {
        firstName: firstNameRef.current.value,
        lastName: lastNameRef.current.value,
        email: email,
        username: generateUsername(),
        uid: userCredential.user.uid, // Add the UID to the post data
        location: [],
        labName: [],
        description: "",
        interests: [],
        education: [],
        currProjects: [],
        profileImageURL: "https://firebasestorage.googleapis.com/v0/b/quuri-website.appspot.com/o/images%2Fstatic%2Fprofile.png?alt=media&token=5c64df22-2c56-4124-9238-519c354b9367",
    });

      setTimeout(function() {window.location.href = '/updateprofile';}, 1000);
    }).catch((error) => {
      console.log(error);
      if (email === '') {
        setErrorMessage('Email is required');
      } else if (password === '' || password.length < 5) {
        setErrorMessage('Password must be atleast 5 characters');
      } else {
        setErrorMessage('Invalid email');
      }
    })
  }

  const generateUsername = () => {
    var adjective = adjectives[Math.floor(Math.random() * adjectives.length)];
    var animal = animals[Math.floor(Math.random() * animals.length)];

    adjective = adjective[0].toUpperCase() + adjective.slice(1);
    animal = animal[0].toUpperCase() + animal.slice(1);

    return adjective + animal;
  }

  return (
    <div>
      <h1 className='signin-header'>Sign Up</h1>

      <div className='signup-container'>
        <div className='signin-left-container'>
          <form onSubmit={signUp}>
            <div className='signin-left-heading'>
              <h1>Welcome!</h1>
              <p>Please sign up to continue</p>
            </div>
            <div className='signin-name-container'>
              <div className='signin-name-input-wrapper'>
                <h1 className='signin-name-heading'>First Name</h1>
                <input placeholder='John' autoComplete="off" name='first-name' ref={firstNameRef}/>
              </div>
              <div className='signin-name-input-wrapper'>
                <h1 className='signin-name-heading'>Last Name</h1>
                <input placeholder='Doe' autoComplete="off" name='last-name' ref={lastNameRef}/>
              </div>
            </div>
            <h1 className='signin-input-heading'>Email Address</h1>
            <div className='signin-input-wrapper'>
              <FaRegEnvelope id='signin-icon' />
              <input type='email' autoComplete='' placeholder='example@gmail.com' value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
            <h1 className='signin-input-heading'>Password</h1>
            <div className='signin-input-wrapper'>
              <FaKey id='signin-key-icon' />
              <input type='password' placeholder='*********' value={password} onChange={(e) => setPassword(e.target.value)} />
            </div>
            <h1 className='signin-input-heading'>Confirm Password</h1>
            <div className='signin-input-wrapper'>
              <FaKey id='signin-key-icon' />
              <input type='password' autoComplete='new-password' placeholder='*********' value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}/>
            </div>
            <button className='signin-submit-button'>Sign up</button>
            <div className='signin-left-bottom-text'>
              <p>or</p>
              <p>Sign up with</p>
            </div>
            <div className="signin-methods">
              <ul className="signin-method-icons">
                <li>
                  <a className="google" href="#"><FcGoogle id='icon'/></a>
                </li>
                <li>
                  <a className="facebook" href="#"><FaFacebookF id='icon'/></a>
                </li>
              </ul>
            </div>
          </form>
        </div>

        <div className='signin-right-container' style={{ backgroundImage: "url('./images/backgrounds/SignupBackground2.png')" }}>
          <h1 className='signup-h1'>Quuri</h1>
          <p>Already have an account?</p>
          <Link to='/login'><button>Login</button></Link>
        </div>
      </div>

      { errorMessage === '' ?
        <></>
      :  
        <div className='signin-error-message'>
          <FaCircleInfo id='error-message-icon'/><p>{errorMessage}</p>
        </div>
      }
    </div>
  )
}

export default Signup