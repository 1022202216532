import React from 'react';

import { Link } from 'react-router-dom';
import '../styles/Home.css';

const RecUserSkeleton = ({ userId, firstname, lastname, username, profileImageURL }) => {
    return (
        <div>
            <Link to={`/profile/${userId}`} className="profile-entry" onClick={() => { window.scroll(0, 0); }}>
                <img src={profileImageURL} alt="Profile" />
                <div className='profile-entry-name'>
                    <h2>{firstname + " " + lastname}</h2>
                    <p>{'@' + username}</p>
                </div>
            </Link>
        </div>
    );
}

export default RecUserSkeleton;