import React, { useState } from 'react';
import { FaLocationDot, FaXmark } from "react-icons/fa6";

const LabNameField = ({ labNameItemList, setLabNameItemList }) => {
    const [labNameInput, setLabNameInput] = useState('');
    const [labNameResults, setLabNameResults] = useState([]);

    const fetchLabNameData = (value) => {
        const results = ['B.S. Biochemistry', 'M.S. Computer Science', 'Ph.D. Physics'].filter((labName) => {
            return value && labName && labName.toLowerCase().includes(value.toLowerCase());
        });
        setLabNameResults(results);
    };

    const handleLabNameChange = (value) => {
        setLabNameInput(value);
        fetchLabNameData(value);
    };

    const clearLabNameInput = () => {
        setLabNameResults([]);
        setLabNameInput('');
    };

    const handleLabNameClick = (value) => {
        if (!labNameItemList.includes(value)) {
            setLabNameItemList([...labNameItemList, value]);
        }
        clearLabNameInput();
    };

    const handleLabNameSubmit = (e) => {
        if (e.keyCode === 13 && labNameInput) {
            handleLabNameClick(labNameInput);
        }
    };

    const handleLabNameDelete = (id) => {
        const updatedLabName = labNameItemList.filter((_, index) => index !== id);
        setLabNameItemList(updatedLabName);
    };

    return (
        <div>
            <h2>Lab Name</h2>
            <div className='update-profile-input-container'>
                <div className='update-profile-search-input-wrapper'>
                    {labNameInput.length === 0 ? (
                        <FaLocationDot id='basic-icon'/>) : (<span id='close-icon' onClick={clearLabNameInput}><FaXmark id='close-icon'/></span>)}
                    <input 
                        placeholder='ex. Burdell Lab'
                        value={labNameInput} 
                        onChange={(e) => handleLabNameChange(e.target.value)} 
                        onKeyDown={(e) => handleLabNameSubmit(e)} 
                    />
                </div>
                <div className='update-profile-results-list'> 
                    {labNameResults.map((labNameResult, id) => {
                        return (
                            <div 
                                className='update-profile-search-result' 
                                key={id} 
                                onClick={() => handleLabNameClick(labNameResult)}
                            >
                                {labNameResult}
                            </div>
                        );
                    })}
                </div>
                <div className='profile-inputted-item-container'>
                    {labNameItemList.map((labNameItem, id) => {
                        return (
                            <div className='profile-inputted-item' key={id}>
                                <p>{labNameItem}</p>
                                <FaXmark id='profile-delete-inputted-item' onClick={() => handleLabNameDelete(id)}/>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default LabNameField;
