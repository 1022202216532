import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { firestore } from '../firebase';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

import Navbar from '../components/navigation/Navbar';
import Footer from '../components/navigation/Footer';
import { Link } from 'react-router-dom';
import '../styles/Project.css';

const ProjectSkeleton = () => {
    const { postId } = useParams();
    const [post, setPost] = useState(null);
    const [error, setError] = useState(null);
    const [userName, setUserName] = useState('');

    useEffect(() => {
        const fetchPost = async () => {
            try {
                const postRef = doc(firestore, 'posts', postId);
                const postDoc = await getDoc(postRef);
                if (postDoc.exists()) {
                    setPost(postDoc.data());
                } else {
                    console.error("No such document!");
                    setError("No such document!");
                }
            } catch (error) {
                console.error("Failed to fetch post data", error);
                setError("Failed to fetch post data");
            }
        };

        fetchPost();
    }, [postId]);

    useEffect(() => {
        const fetchUserName = async (authorUid) => {
            try {
                const db = getFirestore();
                const profileRef = doc(db, 'profiles', authorUid);
                const profileSnap = await getDoc(profileRef);

                if (profileSnap.exists()) {
                    setUserName(" " + profileSnap.data().firstName + " " + profileSnap.data().lastName);
                } else {
                    console.error("No such profile document!");
                }
            } catch (error) {
                console.error("Error fetching profile names:", error);
            }
        };

        if (post && post.author_uid) {
            fetchUserName(post.author_uid);
        }
    }, [post]);

    if (error) {
        return <div>{error}</div>;
    }

    if (!post) {
        return <div>Loading...</div>;
    }

    return (
        <div className='main-container'>
            <Navbar />
            <div className="project-title">
                <h1>{post.title}</h1>
            </div>

            <div className="project-info">
                <div className="project-info-column">
                    <h1>{post.location.map((location, index) => (
                        <React.Fragment key={index}>
                            {location}{index < post.location.length - 1 && '; '}
                        </React.Fragment>
                    ))}
                    </h1>

                    <h1>
                        Principal Investigator: {post.pi}<br />
                        Contributors: {post.contributors.map((contributor, index) => (
                            <React.Fragment key={index}>
                                {contributor}{index < post.contributors.length - 1 && ', '}
                            </React.Fragment>
                        ))}
                    </h1>
                    <h1>Posted By: <u>
                        <Link to={`/profile/${post.author_uid}`} onClick={() => { window.scroll(0, 0); }}>
                            {userName}
                        </Link></u>
                    </h1>

                    <div className="project-tag-list">
                        <div className="project-tags">
                            {post.tags.map((tag, index) => (
                                <p className="project-tag" key={index}>{tag}</p>
                            ))}
                        </div>
                    </div>

                    <p>{post.description}</p>

                    {post.credit && (
                        <a href={post.credit} target="_blank" rel="noopener noreferrer">
                        <h2>**Disclaimer** The scientific article presented in this post is a publicly 
                        published article. It is used in the Quuri MVP solely as an example post. All rights and 
                        credits for the content of the article belong to the original authors and publishers. 
                        This post does not intend to claim any ownership or authorship of the content. 
                        Please refer to the original publication for detailed information and context. <u>Click here for 
                        more information regarding "{post.title}".</u></h2></a>
                    )}
                </div>

                <div className="project-image-column">
                    <img src={post.imageUrl} alt="Post Thumbnail"/>
                </div>
            </div>

            <div className='project-horizontal-spacer'></div>

            <Footer />
        </div>
    );
};

export default ProjectSkeleton;