import React, { useEffect, useState } from 'react';
import '../../styles/compStyles/Footer.css';
import { Link } from 'react-router-dom';
import { FaRegEnvelope, FaLinkedinIn, FaLinkedin } from "react-icons/fa6";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const Footer = () => {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [uid, setUid] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsSignedIn(true);
        setUid(user.uid);
      } else {
        setIsSignedIn(false);
        setUid(null);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  return (
    <footer className="site-footer">
      <hr />
      <div className="footer-container">
        <div className="row">
          <div className="footer-logo col-sm-12 col-md-6">
            <img className='footer-logo-img -z-50' src='https://firebasestorage.googleapis.com/v0/b/quuri-website.appspot.com/o/images%2Fstatic%2FQuuriLogo.png?alt=media&token=48892506-64a2-4278-ab56-974c2236f3e3' alt='Quuri Logo' />
            <div className="col-md-4 col-sm-6 col-xs-12">
              <ul className="social-icons">
                <li>
                  <a className="dribbble" href="mailto:founders@quuri.com"><FaRegEnvelope id='icon' /></a>
                </li>
                <li>
                  <a className="linkedin" href="https://www.linkedin.com/company/quuri/"><FaLinkedinIn id='icon' /></a>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-nav col-xs-6 col-md-3">
            {isSignedIn && (
              <ul className="footer-links">
                <p><Link to={`/profile/${uid}`} onClick={() => { window.scroll(0, 0); }}>My Profile</Link></p>
                <p><Link to="/create_post" onClick={() => { window.scroll(0, 0); }}>Create Post</Link></p>
                <p><Link to="/settings" onClick={() => { window.scroll(0, 0); }}>Settings</Link></p>
              </ul>
            )}
            <ul className="footer-links">
              <p><Link to="/" onClick={() => { window.scroll(0, 0); }}>Home</Link></p>
              <p><Link to="/about" onClick={() => { window.scroll(0, 0); }}>About Us</Link></p>
            </ul>
          </div>
        </div>
      </div>
      <div className="cc-container">
        <div className="row">
          <div className="copyright-text">
            <p>
              Copyright &copy; 2024 All Rights Reserved by
              <a href="#"> Quuri</a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
