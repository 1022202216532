import React, { useState, useEffect } from 'react';
import { firestore } from '../firebase';
import { getAuth, updateEmail, updatePassword } from 'firebase/auth';
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL, getStorage } from '@firebase/storage';

import '../styles/Settings.css';
import Navbar from '../components/navigation/Navbar';
import Footer from '../components/navigation/Footer';
import { FaCircleInfo } from "react-icons/fa6";
import { BsPerson, BsShieldCheck, BsEyeSlash, BsBell } from "react-icons/bs";

const Settings = () => {
    const [imageURL, setImageURL] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    const [username, setUsername] = useState('');
    const [firstname, setFirstname] = useState(null);
    const [lastname, setLastname] = useState(null);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [page, setPage] = useState('profile');
    const [errorMessage, setErrorMessage] = useState('');
    
    const auth = getAuth();
    const storage = getStorage();
    const user = auth.currentUser;

    useEffect(() => {
        if (user) {
            setEmail(user.email);

            const docRef = doc(firestore, 'profiles', user.uid);
            getDoc(docRef).then((doc) => {
                setUsername(doc.data().username);
                setFirstname(doc.data().firstName);
                setLastname(doc.data().lastName);
                setImageURL(doc.data().profileImageURL);
            })
        }
    }, []);
    
    const handleSave = async (e) => {
        e.preventDefault();
        setErrorMessage('');

        if (username.trim() === '') {
            return setErrorMessage('Invalid username');
        }
        if (firstname.trim() === '') {
            return setErrorMessage('Invalid first name');
        }
        if (lastname.trim() === '') {
            return setErrorMessage('Invalid last name');
        }

        if (!(email.includes('@')) || (email.length < 5)) {
            return setErrorMessage('Invalid email address');
        } else if (email.slice(0, email.indexOf('@')).trim() === ''){
            return setErrorMessage('Invalid email address');
        }

        updateEmail(user, email).then(() => {
            console.log('email updated: ' + email);
        }).catch((error) => {
            console.log(error);
        });

        if (password.trim() !== '' && password.length > 5) {
            updatePassword(user, password).then(() => {
                console.log('password updated');
            }).catch((error) => {
                console.log(error);
            });
        }

        await updateDoc(doc(firestore, 'profiles', user.uid), {
            firstName: firstname,
            lastName: lastname,
            email: email,
            username: username,
            profileImageURL: imageURL,
        });

        setTimeout(function() { window.location.reload(); }, 1000);
    };

    const handleImageChange = async (e) => {
        if (e.target.files[0]) {
            try {
                const storageRef = ref(storage, `images/profile-images/${e.target.files[0].name}`);
                const snapshot = await uploadBytes(storageRef, e.target.files[0]);
                setImageURL(await getDownloadURL(snapshot.ref));
                console.log('Image uploaded:', imageURL);
            } catch (error) {
                console.error('Error uploading image:', error);
                setIsUploading(false);
                return;
            }
        }
    };

    const deleteImage = () => {
        setImageURL("https://firebasestorage.googleapis.com/v0/b/quuri-website.appspot.com/o/images%2Fstatic%2Fprofile.png?alt=media&token=5c64df22-2c56-4124-9238-519c354b9367");
    }

    return (
        <div className='settings-main-container'>
            <Navbar />
            <h1>Account Settings</h1>
            <div className='settings-container'>
                <div className='settings-left-bar'>
                    <button className={page === 'profile' ? 'active' : 'inactive'} onClick={() => setPage('profile')}><BsPerson id='settings-icon' />Profile Settings</button>
                    <button className={page === 'privacy' ? 'active' : 'inactive'} onClick={() => setPage('privacy')}><BsEyeSlash id='settings-icon' />Privacy</button>
                    <button className={page === 'notifications' ? 'active' : 'inactive'} onClick={() => setPage('notifications')}><BsBell id='settings-icon' />Notifications</button>
                    <button className={page === 'verification' ? 'active' : 'inactive'} onClick={() => setPage('verification')}><BsShieldCheck id='settings-icon' />Verification</button>
                </div>
                <form className='settings-info-container'>
                    {page === 'profile' ? 
                        <div className='profile-settings'>
                            <div className='profile-image-settings'>
                                <img src={imageURL} />
                                <label for='image-upload' className='button-1'>
                                    Upload New
                                </label>
                                <input id='image-upload' type='file' accept='image/*' onChange={(e) => handleImageChange(e)} />
                                <button className='button-2' type='button' onClick={() => deleteImage()}>Delete</button>
                            </div>
                            <div className='profile-settings-inputs'>
                                <div className='profile-settings-row'>
                                    <div className='settings-input-item'>
                                        <h1>Username</h1>
                                        <input placeholder={username} onChange={(e) => setUsername(e.target.value)}/>
                                    </div>
                                </div>
                                <div className='profile-settings-row'>
                                    <div className='settings-input-item'>
                                        <h1>First Name</h1>
                                        <input placeholder={firstname} onChange={(e) => setFirstname(e.target.value)}/>
                                    </div>
                                    <div className='settings-input-item'>
                                        <h1>Last Name</h1>
                                        <input placeholder={lastname} onChange={(e) => setLastname(e.target.value)}/>
                                    </div>
                                </div>
                                <div className='profile-settings-row'>
                                    <div className='settings-input-item'>
                                        <h1>Email</h1>
                                        <input placeholder={email} onChange={(e) => setEmail(e.target.value)}/>
                                    </div>
                                    <div className='settings-input-item'>
                                        <h1>Password</h1>
                                        <input placeholder='*********' type='password' autoComplete='new-password' onChange={(e) => setPassword(e.target.value)}/>
                                    </div>
                                </div>
                            </div>
                            <button className='save-button' type='submit' onClick={handleSave} disabled={isUploading}>
                                {isUploading ? 'Uploading...' : 'Save'}
                            </button>
                        </div>
                    :
                        <div className='inactive-settings'>
                            <p>Coming Soon</p>
                        </div>
                    }
                    
                </form>
            </div>
            { errorMessage === '' ?
                <></>
            :  
                <div className='settings-error-message'>
                <FaCircleInfo id='error-message-icon'/><p>{errorMessage}</p>
                </div>
            }
            <Footer />
        </div>
  )
}

export default Settings