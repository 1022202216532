const calculateTimeDifference = (timestamp) => {
        const createdAt = Math.floor((timestamp.toDate()).getTime());
  
        // Get the current time
        const now = (new Date().getTime());
        
        // Calculate the difference in milliseconds
        const diff = now - createdAt;
        
        // Convert difference from milliseconds to seconds
        const seconds = Math.floor(diff / 1000);
        
        // Convert difference from seconds to more readable units
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const months = Math.floor(days / 30); // Approximate month
        const years = Math.floor(days / 365); // Approximate year

        let result = '';

        if (years > 0) {
                if (years > 1) {
                        result = '>' + years + ' years ago';
                } else if (years === 1) {
                        result = years + ' year ago';
                }
        } else if (months > 0) {
                if (months > 1) {
                        result = months + ' months ago';
                } else if (months === 1) {
                        result = months + ' month ago';
                }
        } else if (days > 0) {
                if (days > 1) {
                        result = days + ' days ago';
                } else if (days === 1) {
                        result = days + ' day ago';
                }
        } else if (hours > 0) {
                if (hours > 1) {
                        result = hours + ' hours ago';
                } else if (years === 1) {
                        result = hours + ' hour ago';
                }
        } else if (minutes > 0) {
                if (minutes > 1) {
                        result = minutes + ' minutes ago';
                } else if (minutes === 1) {
                        result = minutes + ' minute ago';
                }
        } else if (seconds > 0) {
                if (seconds > 1) {
                        result = seconds + ' seconds ago';
                } else if (seconds === 1) {
                        result = seconds + ' second ago';
                }
        }
    
        return result;
};

export default calculateTimeDifference;