import React, { useState, useRef } from 'react';
import { firestore } from '../firebase';
import { addDoc, collection, updateDoc, arrayUnion, doc } from '@firebase/firestore';
import { getAuth } from 'firebase/auth';
import { ref, uploadBytes, getDownloadURL, getStorage } from '@firebase/storage';

import '../styles/CreatePost.css';
import { FaRegCircleUser, FaTag, FaXmark, FaLocationDot, FaHatWizard } from "react-icons/fa6";
import Navbar from '../components/navigation/Navbar';
import Footer from '../components/navigation/Footer';

const CreatePost = () => {
    const databaseCollection = collection(firestore, 'posts');
    const titleRef = useRef();
    const descRef = useRef();
    const [image, setImage] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const storage = getStorage();

    const handleSave = async (e) => {
        e.preventDefault();

        const auth = getAuth();
        const user = auth.currentUser;

        if (!user) {
            console.log('No user is signed in');
            return;
        }

        const uid = user.uid;

        console.log(locationItemList);
        console.log(piItemList);
        console.log(contItemList);
        console.log(tagsItemList);
        console.log(titleRef.current.value);
        console.log(descRef.current.value);

        setIsUploading(true);

        let imageUrl = 'https://firebasestorage.googleapis.com/v0/b/quuri-website.appspot.com/o/images%2Fproject-images%2F16x9_Transparent.png?alt=media&token=14c55076-f80c-4119-b56f-b510dd7ff4c4';
        if (image) {
            try {
                const storageRef = ref(storage, `images/project-images/${image.name}`);
                const snapshot = await uploadBytes(storageRef, image);
                imageUrl = await getDownloadURL(snapshot.ref);
                console.log('Image uploaded:', imageUrl);
            } catch (error) {
                console.error('Error uploading image:', error);
                setIsUploading(false);
                return;
            }
        }

        let data = {
            location: locationItemList,
            pi: piItemList,
            contributors: contItemList,
            tags: tagsItemList,
            title: titleRef.current.value,
            description: descRef.current.value,
            uid: uid, // Add the UID to the post data
            timestamp: new Date(),
            imageUrl: imageUrl,
            author_uid: user.uid,
        };

        try {
            const docRef = await addDoc(databaseCollection, data);
            console.log('Post saved successfully');

            // Update the post with the document ID as the UID
            await updateDoc(docRef, {
                uid: docRef.id
            });

            // Update the user's document with the new post UID
            const userDocRef = doc(firestore, 'profiles', uid);
            await updateDoc(userDocRef, {
                currProjects: arrayUnion(docRef.id)
            });
            console.log('User document updated with new post UID');
        } catch (e) {
            console.error('Error saving post:', e);
        } finally {
            setIsUploading(false);
        }

        setTimeout(function() {window.location.href = '/';}, 1000);
    };

    const handleImageChange = (e) => {
        if (e.target.files[0]) {
            setImage(e.target.files[0]);
        }
    };

    /*-- Location  Input --*/
    const [locationInput, setLocationInput] = useState('');
    const [locationResults, setLocationResults] = useState([]);
    const [locationItemList, setLocationItemList] = useState([]);

    const fetchLocationData = (value) => {
        const results = ['Georgia Institute of Technology', 'Emory University'].filter((name) => {
            return value && name && name.toLowerCase().includes(value);
        });
        setLocationResults(results);
    };

    const handleLocationChange = (value) => {
        setLocationInput(value);
        fetchLocationData(value);
    };

    const clearLocationInput = () => {
        setLocationResults([]);
        setLocationInput('');
    };

    const handleLocationClick = (value) => {
        locationItemList.push(value);
        clearLocationInput();
    };

    const handleLocationSubmit = (e) => {
        if (e.keyCode === 13) {
            locationItemList.push(e.target.value);
            clearLocationInput();
        }
    };

    const handleLocationDelete = (id) => {
        const dummyArray = locationItemList.filter((item, index) => index !== id);
        setLocationItemList(dummyArray);
    };

    /*-- Personal Investigator Input --*/
    const [piInput, setPiInput] = useState('');
    const [piResults, setPiResults] = useState([]);
    const [piItemList, setPiItemList] = useState([]);

    const fetchPiData = (value) => {
        const results = ['Jia Song', 'Reza Abbasi-Asl', 'Stan Gronthos', 'Chung Hyun Goh', 'Aldons J Lusis', 'Elena L. Glassman'].filter((name) => {
            return value && name && name.toLowerCase().includes(value);
        });
        setPiResults(results);
    };

    const handlePiChange = (value) => {
        setPiInput(value);
        fetchPiData(value);
    };

    const clearPiInput = () => {
        setPiResults([]);
        setPiInput('');
    };

    const handlePiClick = (value) => {
        piItemList.push(value);
        clearPiInput();
    };

    const handlePiSubmit = (e) => {
        if (e.keyCode === 13) {
            piItemList.push(e.target.value);
            clearPiInput();
        }
    };

    const handlePiDelete = (id) => {
        const dummyArray = piItemList.filter((item, index) => index !== id);
        setPiItemList(dummyArray);
    };

    /*-- Contributors Input --*/
    const [contInput, setContInput] = useState('');
    const [contResults, setContResults] = useState([]);
    const [contItemList, setContItemList] = useState([]);

    const fetchContData = (value) => {
        const results = ['biology', 'computing'].filter((tag) => {
            return value && tag && tag.toLowerCase().includes(value);
        });
        setContResults(results);
    };

    const handleContChange = (value) => {
        setContInput(value);
        fetchContData(value);
    };

    const clearContInput = () => {
        setContResults([]);
        setContInput('');
    };

    const handleContClick = (value) => {
        contItemList.push(value);
        clearContInput();
    };

    const handleContSubmit = (e) => {
        if (e.keyCode === 13) {
            contItemList.push(e.target.value);
            clearContInput();
        }
    };

    const handleContDelete = (id) => {
        const dummyArray = contItemList.filter((item, index) => index !== id);
        setContItemList(dummyArray);
    };

    /*-- Tags Input --*/
    const [tagsInput, setTagsInput] = useState('');
    const [tagsResults, setTagsResults] = useState([]);
    const [tagsItemList, setTagsItemList] = useState([]);

    const fetchTagsData = (value) => {
        const results = ['biology', 'computing'].filter((tag) => {
            return value && tag && tag.toLowerCase().includes(value);
        });
        setTagsResults(results);
    };

    const handleTagsChange = (value) => {
        setTagsInput(value);
        fetchTagsData(value);
    };

    const clearTagsInput = () => {
        setTagsResults([]);
        setTagsInput('');
    };

    const handleTagsClick = (value) => {
        tagsItemList.push(value);
        clearTagsInput();
    };

    const handleTagsSubmit = (e) => {
        if (e.keyCode === 13) {
            tagsItemList.push(e.target.value);
            clearTagsInput();
        }
    };

    const handleTagsDelete = (id) => {
        const dummyArray = tagsItemList.filter((item, index) => index !== id);
        setTagsItemList(dummyArray);
    };

    return (
        <div>
            <Navbar />
            <div className='create-post-main-container' style={{ backgroundImage: "url('./images/backgrounds/PostBackground.png')" }}>
                <h1>Create a Post</h1>

                <div className='create-post-inner-container'>
                    {/* Location Input */}
                    <div className='create-post-input-container'>
                        <div className='create-post-search-input-wrapper' setLocationResults={setLocationResults}>
                            {locationInput.length === 0 ? (
                                <FaLocationDot id='basic-icon'/>
                            ) : (
                                <FaXmark id='close-icon' onClick={clearLocationInput} />)}
                            <input placeholder='Location' value={locationInput} onChange={(e) => handleLocationChange(e.target.value)} onKeyDown={(e) => handleLocationSubmit(e)} />
                        </div>

                        <div className='create-post-results-list'> 
                            {locationResults.map((locationResult, id) => {
                                return <div className='create-post-search-result' href={locationResult} key={id} onClick={(e) => handleLocationClick(locationResult)}>{locationResult}</div>;
                            })}
                        </div>

                        <div className='inputted-item-container'>
                            {locationItemList.map((locationItem, id) => {
                                return (
                                    <div className='inputted-item' key={id}>
                                        <p>{locationItem}</p>
                                        <FaXmark id='delete-inputted-item' onClick={(e) => handleLocationDelete(id)}/>
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                    <div className='create-post-vertical-spacer'></div>

                    {/* PI Input */}
                    <div className='create-post-input-container'>
                        <div className='create-post-search-input-wrapper' setPiResults={setPiResults}>
                            {piInput.length === 0 ? (
                                <FaHatWizard id='basic-icon'/>
                            ) : (
                                <FaXmark id='close-icon' onClick={clearPiInput} />)}
                            <input placeholder='Principal Investigator' value={piInput} onChange={(e) => handlePiChange(e.target.value)} onKeyDown={(e) => handlePiSubmit(e)} />
                        </div>

                        <div className='create-post-results-list'> 
                            {piResults.map((piResult, id) => {
                                return <div className='create-post-search-result' href={piResult} key={id} onClick={(e) => handlePiClick(piResult)}>{piResult}</div>;
                            })}
                        </div>

                        <div className='inputted-item-container'>
                            {piItemList.map((piItem, id) => {
                                return (
                                    <div className='inputted-item' key={id}>
                                        <p>{piItem}</p>
                                        <FaXmark id='delete-inputted-item' onClick={(e) => handlePiDelete(id)}/>
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                    <div className='create-post-vertical-spacer'></div>

                    {/* Contributor Input */}
                    <div className='create-post-input-container'>
                        <div className='create-post-search-input-wrapper' setContResults={setContResults}>
                            {contInput.length === 0 ? (
                                <FaRegCircleUser id='basic-icon'/>
                            ) : (
                                <FaXmark id='close-icon' onClick={clearContInput} />)}
                            <input placeholder='Contributors' value={contInput} onChange={(e) => handleContChange(e.target.value)} onKeyDown={(e) => handleContSubmit(e)} />
                        </div>

                        <div className='create-post-results-list'> 
                            {contResults.map((contResult, id) => {
                                return <div className='create-post-search-result' href={contResult} key={id} onClick={(e) => handleContClick(contResult)}>{contResult}</div>;
                            })}
                        </div>

                        <div className='inputted-item-container'>
                            {contItemList.map((contItem, id) => {
                                return (
                                    <div className='inputted-item' key={id}>
                                        <p>{contItem}</p>
                                        <FaXmark id='delete-inputted-item' onClick={(e) => handleContDelete(id)}/>
                                    </div>
                                );
                            })}                            
                        </div>
                    </div>

                    <div className='create-post-vertical-spacer'></div>

                    {/* Tag Input */}
                    <div className='create-post-input-container'>
                        <div className='create-post-search-input-wrapper' setTagsResults={setTagsResults}>
                            {tagsInput.length === 0 ? (
                                <FaTag id='basic-icon'/>
                            ) : (
                                <FaXmark id='close-icon' onClick={clearTagsInput} />)}
                            <input placeholder='Tags' value={tagsInput} onChange={(e) => handleTagsChange(e.target.value)} onKeyDown={(e) => handleTagsSubmit(e)} />
                        </div>

                        <div className='create-post-results-list'> 
                            {tagsResults.map((tagsResult, id) => {
                                return <div className='create-post-search-result' href={tagsResult} key={id} onClick={(e) => handleTagsClick(tagsResult)}>{tagsResult}</div>;
                            })}
                        </div>

                        <div className='inputted-item-container'>
                            {tagsItemList.map((tagsItem, id) => {
                                return (
                                    <div className='inputted-item' key={id}>
                                        <p>{tagsItem}</p>
                                        <FaXmark id='delete-inputted-item' onClick={(e) => handleTagsDelete(id)}/>                                               
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                    {/* Title Input */}
                    <div className='title-input-wrapper'>
                        <input placeholder='Title' name='title' ref={titleRef} />
                    </div>

                    {/* Description Input */}
                    <div className='description-input-wrapper'>

                        <textarea 
                            placeholder='Description' 
                            name='description' 
                            ref={descRef} 
                            rows="5"
                            style={{ width: '100%', resize: 'vertical', padding: '10px', borderRadius: '5px' }}
                        />
                    </div>

                    <div className='create-post-vertical-spacer'></div>

                    {/* Image Upload Input */}
                    <div className='image-upload-wrapper'>
                        <input type='file' accept='image/*' onChange={handleImageChange} />
                    </div>

                    <button className='submit-post-button' type='submit' onClick={handleSave} disabled={isUploading}>
                        {isUploading ? 'Uploading...' : 'Create Post'}
                    </button>

                    <div className='create-post-vertical-spacer'></div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default CreatePost;