import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getFirestore} from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCHiCMoCrDibXfkfLbDNpga3SZBNjQXFAA",
  authDomain: "quuri-website.firebaseapp.com",
  projectId: "quuri-website",
  storageBucket: "quuri-website.appspot.com",
  messagingSenderId: "750906779463",
  appId: "1:750906779463:web:929a89c3c5ca5af9a2ce62",
  measurementId: "G-XY0B07DGDE"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const analytics = getAnalytics(app);
export const firestore = getFirestore(app);
export const storage = getStorage(app);