import React, { useState, useEffect } from 'react';
import { firestore } from '../firebase.js';
import { collection, getDocs } from '@firebase/firestore';

import '../styles/Home.css';
import RecUserSkeleton from './RecUserSkeleton';

const NondescriptColumn = () => { 
    const [profiles, setProfiles] = useState([]);

    useEffect(() => {
      const fetchUsers = async () => {
          const profilesCollection = collection(firestore, 'profiles');
          const profileSnapshot = await getDocs(profilesCollection);
          const profilesList = profileSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setProfiles(profilesList);
      };

      fetchUsers();
    }, []);

    return (
        <div class="suggestions-column">
          <div class="suggested-profiles"> 
            <h1>Suggested People</h1>
            <div class='profiles'>
                {profiles.map((profile) => (
                  <RecUserSkeleton
                  userId={profile.id}
                  firstname={profile.firstName}
                  lastname={profile.lastName}
                  username={profile.username}
                  profileImageURL={profile.profileImageURL || './images/posts/default.jpg'}
                />
                ))}
              </div> 
          </div>
          
          <div className='home-horizontal-line'></div>

          <p className='disclaimer-text'>The content available on this website is intended for public viewing and is 
            not considered confidential. By accessing and using this website, you 
            acknowledge that any information, comments, or material you submit or share 
            becomes publicly accessible and may be viewed, copied, and distributed by 
            others. We do not guarantee the privacy or security of any information you 
            provide through this site. For your own protection, avoid sharing sensitive 
            personal information and use discretion when contributing content.</p>
        </div>
    )
}

export default NondescriptColumn