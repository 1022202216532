import React from 'react';

const RoleField = ({ roles, selectedRoles, setSelectedRoles }) => {
    const handleRoleChange = (role) => {
        setSelectedRoles((prevSelectedRoles) =>
            prevSelectedRoles.includes(role)
                ? prevSelectedRoles.filter((r) => r !== role)
                : [...prevSelectedRoles, role]
        );
    };

    return (
        <div>
            <h2>Select Role(s)</h2>
            <div className='roles-input-wrapper'>
                {roles.map((role) => (
                    <div key={role}>
                        <input
                            type='checkbox'
                            id={role}
                            value={role}
                            checked={selectedRoles.includes(role)}
                            onChange={() => handleRoleChange(role)}
                        />
                        <label htmlFor={role}>{role}</label>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default RoleField;
