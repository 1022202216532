import React, { useState } from 'react';
import { FaGraduationCap, FaXmark } from "react-icons/fa6";

const EducationField = ({ educationItemList, setEducationItemList }) => {
    const [educationInput, setEducationInput] = useState('');
    const [educationResults, setEducationResults] = useState([]);

    const fetchEducationData = (value) => {
        const results = ['B.S. Biochemistry', 'M.S. Computer Science', 'Ph.D. Physics'].filter((education) => {
            return value && education && education.toLowerCase().includes(value.toLowerCase());
        });
        setEducationResults(results);
    };

    const handleEducationChange = (value) => {
        setEducationInput(value);
        fetchEducationData(value);
    };

    const clearEducationInput = () => {
        setEducationResults([]);
        setEducationInput('');
    };

    const handleEducationClick = (value) => {
        if (!educationItemList.includes(value)) {
            setEducationItemList([...educationItemList, value]);
        }
        clearEducationInput();
    };

    const handleEducationSubmit = (e) => {
        if (e.keyCode === 13 && educationInput) {
            handleEducationClick(educationInput);
        }
    };

    const handleEducationDelete = (id) => {
        const updatedEducation = educationItemList.filter((_, index) => index !== id);
        setEducationItemList(updatedEducation);
    };

    return (
        <div>
            <h2>Education</h2>
            <div className='update-profile-input-container'>
                <div className='update-profile-search-input-wrapper'>
                    {educationInput.length === 0 ? (
                        <FaGraduationCap id='basic-icon'/>) : (<span id='close-icon' onClick={clearEducationInput}><FaXmark id='close-icon'/></span>)}
                    <input 
                        placeholder='ex. B.S. Biochemistry, Georgia Institute of Technology 1990'
                        value={educationInput} 
                        onChange={(e) => handleEducationChange(e.target.value)} 
                        onKeyDown={(e) => handleEducationSubmit(e)} 
                    />
                </div>
                <div className='update-profile-results-list'> 
                    {educationResults.map((educationResult, id) => {
                        return (
                            <div 
                                className='update-profile-search-result' 
                                key={id} 
                                onClick={() => handleEducationClick(educationResult)}
                            >
                                {educationResult}
                            </div>
                        );
                    })}
                </div>
                <div className='profile-inputted-item-container'>
                    {educationItemList.map((educationItem, id) => {
                        return (
                            <div className='profile-inputted-item' key={id}>
                                <p>{educationItem}</p>
                                <FaXmark id='profile-delete-inputted-item' onClick={() => handleEducationDelete(id)}/>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default EducationField;
